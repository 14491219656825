import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import InputRow from '../../../Components/InputRow';
import TextArea from '../../../Components/TextArea';
import ProgramHeader from '../../../Components/ProgramHeader';
import PaymentDetail from '../../../Components/PaymentDetail';
import AuthRender from '../../../Components/AuthRender';

import CURRENCIES from '../../../StaticData/Currencies';
import { PAYMENT_METHODS_REQUIRED } from '../../../StaticData/PaymentMethods';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIHelper from '../../../Helper/APIHelper';
import ThingsToSelect from '../../../Helper/ThingsToSelect';
import ValidatePaymentDetail from '../../../Helper/ValidatePaymentDetail';
import AuthHelper from '../../../Helper/AuthHelper';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

export default class AddEditExpense extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			incentiveExpenseCategories: [],
			suppliers: [],
			paymentDate: moment(new Date()),
			paymentTime: '',
			note: '',
			paymentMethod: PAYMENT_METHODS_REQUIRED[0],
			currency: CURRENCIES[0],
			currencyRate: 1,
			title: '',
			bank: null,
			checkNumber: '',
			bankAccount: null,
			creditCard: null,
			incentiveExpenseList: [
				{
					title: '',
					pricePerPiece: 0,
					quantity: 0,
				},
			],
			incentiveExpenseCategory: null,
			supplier: null,
			isFinanceReviewed: null,
			categories: [],
			approvalStatus: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			let initCreate = true;
			let initUpdate = true;
			if (this.isUpdate()) {
				initCreate = await this.initCreate();
				initUpdate = await this.initUpdate();
			} else {
				initCreate = await this.initCreate();
			}

			if (initCreate && initUpdate) {
				this.setState({
					isLoading: false,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	initCreate = async () => {
		const { programId, expenseCategoryId } = this.props.match.params;

		let requestProgram = APIGet('incentive/programs/' + programId);
		let requestCategories = APIGet('incentiveExpenseCategories');
		requestProgram = await requestProgram;
		requestCategories = await requestCategories;
		if (APIHelper.handleAPIResponse(this, requestProgram, requestCategories)) {
			const program = requestProgram.data;
			const categories = requestCategories.data;
			const incentiveExpenseCategories = ThingsToSelect(requestCategories.data);
			let suppliers = [];
			let incentiveExpenseCategory = null;
			if (expenseCategoryId) {
				incentiveExpenseCategory = incentiveExpenseCategories.filter(e => e.value == expenseCategoryId)[0];
				suppliers = ThingsToSelect(categories.filter(category => category.id == expenseCategoryId)[0].expenseSuppliers);
			}
			this.setState({
				program,
				incentiveExpenseCategories,
				incentiveExpenseCategory,
				categories,
				suppliers,
			});

			return true;
		} else if (requestProgram.status === 404) {
			toast.warning('ไม่พบโปรแกรมดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	initUpdate = async () => {
		const { programId, expenseCategoryId, expenseId } = this.props.match.params;
		let requestExpense = APIGet('incentive/programs/' + programId + '/expenses/' + expenseCategoryId + '/' + expenseId);
		requestExpense = await requestExpense;
		if (APIHelper.handleAPIResponse(this, requestExpense)) {
			const expense = requestExpense.data;
			let isFinanceReviewed = null;
			if (AuthHelper.getUserDetail().authRole === 'Finance') {
				if (expense.financeStaff) {
					isFinanceReviewed = true;
				} else {
					isFinanceReviewed = false;
				}
			}

			console.log('expense: ', expense);
			this.setState({
				note: expense.note,
				currency: CURRENCIES.filter(c => c.value === expense.currency)[0],
				currencyRate: expense.currencyRate,
				title: expense.title,
				incentiveExpenseList: expense.incentiveExpenseList,
				incentiveExpenseCategory: {
					value: expense.incentiveExpenseCategory.id,
					label: expense.incentiveExpenseCategory.name,
				},
				supplier: {
					value: expense.supplier.id,
					label: expense.supplier.name,
				},
				paymentMethod: PAYMENT_METHODS_REQUIRED.filter(p => p.value === expense.paymentMethod)[0],
				bank: expense.bank
					? {
							value: expense.bankId,
							label: expense.bank.name,
					  }
					: null,
				checkNumber: expense.checkNumber,
				bankAccount: expense.bankAccount
					? {
							value: expense.bankAccountId,
							label: expense.bankAccount.name,
					  }
					: null,
				paymentDate: moment(new Date(expense.paymentDate)),
				paymentTime: expense.paymentTime,
				creditCard: expense.creditCard
					? {
							value: expense.creditCardId,
							label: expense.creditCard.name,
					  }
					: null,
				isFinanceReviewed,
				approvalStatus: expense.approvalStatus,
			});

			return true;
		} else if (requestExpense.status === 404) {
			toast.warning('ไม่พบข้อมูลดังกล่าว');
			return false;
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง');
			return false;
		}
	};

	isUpdate = () => {
		const { expenseId } = this.props.match.params;
		if (expenseId) {
			return true;
		} else {
			return false;
		}
	};

	save = async () => {
		const { programId, expenseId } = this.props.match.params;
		if (this.validateSaveState()) {
			this.setState({
				isLoading: true,
			});

			let data = {
				incentiveProgramId: programId,
				title: this.state.title,
				total: this.calculateTotalPrice(),
				currencyRate: this.state.currencyRate,
				currency: this.state.currency.value,
				note: this.state.note,
				incentiveExpenseList: this.state.incentiveExpenseList,
				incentiveExpenseCategoryId: this.state.incentiveExpenseCategory.value,
				supplierId: this.state.supplier.value,
				paymentMethod: this.state.paymentMethod.value,
				paymentDate: this.state.paymentDate.format('YYYY-MM-DD'),
				isFinanceReviewed: this.state.isFinanceReviewed,
			};

			// console.log('data: ', data);

			if (this.state.paymentMethod.value === 'Transfer') {
				data.bankAccountId = this.state.bankAccount.value;
				data.paymentTime = this.state.paymentTime;
			} else if (this.state.paymentMethod.value === 'Check') {
				data.bankId = this.state.bank.value;
				data.checkNumber = this.state.checkNumber;
			} else if (this.state.paymentMethod.value === 'Credit Card') {
				data.creditCardId = this.state.creditCard.value;
			}

			let request = null;
			if (this.isUpdate()) {
				request = await APIPut('incentive/programs/' + programId + '/expenses/' + expenseId, data);
			} else {
				request = await APIPost('incentive/programs/' + programId + '/expenses', data);
			}

			if (APIHelper.handleAPIResponse(this, request)) {
				toast.success(this.isUpdate() ? 'แก้ไขรายรับเรียบร้อยแล้ว' : 'เพิ่มรายรับเรียบร้อยแล้ว');
				const result = request.data;
				this.props.history.push(
					AppConfig.appPath + '/programs/incentive/' + programId + '/expenses/' + result.incentiveExpenseCategoryId + '/' + result.id
				);
			} else {
				toast.error('เกิดปัญหาในการเพิ่มรายรับ');
				this.setState({
					isLoading: false,
				});
			}
		}
	};

	validateSaveState = () => {
		const incentiveExpenseCategory = this.state.incentiveExpenseCategory && this.state.incentiveExpenseCategory.value;
		if (!incentiveExpenseCategory) {
			toast.warn('กรุณาระบุประเภท');
		}

		const supplier = this.state.supplier && this.state.supplier.value;
		if (!supplier) {
			toast.warn('กรุณาระบุ Supplier');
		}

		const title = this.state.title.trim() !== '';
		if (!title) {
			toast.warn('กรุณาระบุรายการหลัก');
		}

		const currencyRate = !isNaN(this.state.currencyRate);
		if (!currencyRate) {
			toast.warn('กรุณาระบุอัตราแลกเปลี่ยนเงินบาท โดยกรอกเป็นตัวเลขและจุดทศนิยมเท่านั้น (ไม่ต้องมี ,)');
		}

		const lists = this.state.incentiveExpenseList.length > 0;
		if (!lists) {
			toast.warn('กรุณาระบุรายการ');
		}

		let listContent = true;
		this.state.incentiveExpenseList.forEach(row => {
			listContent = listContent && (row.title.trim() !== '' && !isNaN(row.pricePerPiece) && !isNaN(row.quantity));
		});

		if (!listContent) {
			toast.warn('กรุณาระบุข้อมูลในรายการให้ครบและถูกต้อง');
		}

		const paymentDetail = ValidatePaymentDetail(this.state, toast);

		return incentiveExpenseCategory && supplier && title && currencyRate && lists && listContent && paymentDetail;
	};

	onTextChange = (type, value) => {
		console.log(type, value);
		this.setState({
			[type]: value,
		});
	};

	handleSelectChange = (type, value) => {
		console.log(type, value);
		if (type === 'currency') {
			if (value && value.value === 'THB') {
				this.setState({
					[type]: value,
					currencyRate: 1,
				});
			} else {
				this.setState({
					[type]: value,
					currencyRate: 0,
				});
			}
		} else {
			this.setState({
				[type]: value,
			});
		}
	};

	handleCategoryChange = value => {
		const expenseCategoryId = value.value;
		const suppliers = ThingsToSelect(this.state.categories.filter(category => category.id == expenseCategoryId)[0].expenseSuppliers);
		this.setState({
			incentiveExpenseCategory: value,
			supplier: null,
			suppliers,
		});
	};

	onPaymentDateChange = newDate => {
		this.setState({
			paymentDate: newDate,
		});
	};

	onExpenseListChange = (key, value, index) => {
		// console.log(key, value, index);
		if (key === 'pricePerPiece' || key === 'quantity') {
			if (value < 0) {
				value = value * -1;
			}
		}
		let incentiveExpenseList = JSON.parse(JSON.stringify(this.state.incentiveExpenseList));
		incentiveExpenseList[index][key] = value;
		this.setState({
			incentiveExpenseList,
		});
	};

	calculateTotalPrice = () => {
		let totalPrice = 0;
		this.state.incentiveExpenseList.forEach(expense => {
			totalPrice += expense.pricePerPiece * expense.quantity;
		});

		return totalPrice;
	};

	calculateTotalPriceTHB = () => {
		const price = this.calculateTotalPrice();
		const result = price * this.state.currencyRate;

		return result;
	};

	addExpenseRow = () => {
		let incentiveExpenseList = JSON.parse(JSON.stringify(this.state.incentiveExpenseList));
		incentiveExpenseList.push({
			title: '',
			pricePerPiece: 0,
			quantity: 0,
		});

		this.setState({
			incentiveExpenseList,
		});
	};

	deleteExpenseRow = index => {
		let incentiveExpenseList = JSON.parse(JSON.stringify(this.state.incentiveExpenseList));
		incentiveExpenseList.splice(index, 1);
		this.setState({
			incentiveExpenseList,
		});
	};

	toggleFinanceReviewed = () => {
		this.setState({
			isFinanceReviewed: !this.state.isFinanceReviewed,
		});
	};

	isAllowEdited = approvalStatus => {
		if (AuthHelper.hasRight('Admin', 'Finance')) {
			return true;
		}

		if (approvalStatus === 'Approved') {
			return false;
		}

		return true;
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program } = this.state;
		const { programId, expenseCategoryId, expenseId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter
					link={
						this.isUpdate()
							? AppConfig.appPath + '/programs/incentive/' + programId + '/expenses/' + expenseCategoryId + '/' + expenseId
							: AppConfig.appPath + '/programs/incentive/' + programId + '/expenses/' + expenseCategoryId
					}
				/>
				<ProgramHeader program={program} />
				<div className="section">
					<div className="columns">
						<div className="column is-6">
							<h3 className="title is-3">เพิ่มรายจ่าย</h3>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12">
							<PaymentDetail
								paymentMethod={this.state.paymentMethod}
								paymentTime={this.state.paymentTime}
								bank={this.state.bank}
								bankAccount={this.state.bankAccount}
								creditCard={this.state.creditCard}
								checkNumber={this.state.checkNumber}
								currency={this.state.currency}
								currencyRate={this.state.currencyRate}
								paymentDate={this.state.paymentDate}
								handleSelectChange={this.handleSelectChange}
								onPaymentDateChange={this.onPaymentDateChange}
								onTextChange={this.onTextChange}
							/>
							<div className="columns row">
								<div className="column is-3">
									<label className="label">ประเภท</label>
									<Select
										name="incentiveExpenseCategory"
										value={this.state.incentiveExpenseCategory}
										clearable={false}
										onChange={this.handleCategoryChange}
										options={this.state.incentiveExpenseCategories}
									/>
								</div>
								<div className="column is-3">
									<label className="label">Supplier</label>
									<Select
										name="supplier"
										value={this.state.supplier}
										clearable={false}
										onChange={value => this.handleSelectChange('supplier', value)}
										options={this.state.suppliers}
									/>
								</div>
								{this.state.currency.value !== 'THB' ? (
									<div className="column is-6" style={{ textAlign: 'right' }}>
										<h5 className="subtitle is-5">ยอดรวม (THB)</h5>
										<h3 className="title is-3">
											<NumberColor number={this.calculateTotalPriceTHB()} isExpense={true} />
										</h3>
									</div>
								) : null}
							</div>
							<div className="columns row">
								<div className="column is-8">
									<InputRow
										title="รายการหลัก"
										labelSize="s"
										placeholder="รายการหลัก"
										keyProps="title"
										onInputChange={this.onTextChange}
										value={this.state.title}
										disabled={false}
										isMandatory={false}
									/>
								</div>
								<div className="column is-4" style={{ textAlign: 'right' }}>
									<h5 className="subtitle is-5">ยอดรวม ({this.state.currency.value})</h5>
									<h3 className="title is-3">
										<NumberColor number={this.calculateTotalPrice()} isExpense={true} />
									</h3>
								</div>
							</div>
							<div className="row table-container">
								<table className="table is-hoverable is-fullwidth is-striped">
									<thead>
										<tr>
											<td width="55%">รายการ</td>
											<td width="20%">ราคาต่อหน่วย ({this.state.currency.value})</td>
											<td width="20%">จำนวน</td>
											<td width="5%" />
										</tr>
									</thead>
									<tbody>
										{this.state.incentiveExpenseList.map((row, index) => (
											<tr key={'addIncentiveExpenseTable' + index}>
												<td>
													<InputRow
														placeholder="รายการ"
														keyProps="title"
														onInputChange={(key, value) => this.onExpenseListChange(key, value, index)}
														value={row.title}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<InputRow
														placeholder="ราคาต่อหน่วย"
														keyProps="pricePerPiece"
														onInputChange={(key, value) => this.onExpenseListChange(key, value, index)}
														value={row.pricePerPiece}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<InputRow
														placeholder="จำนวน"
														keyProps="quantity"
														onInputChange={(key, value) => this.onExpenseListChange(key, value, index)}
														value={row.quantity}
														disabled={false}
														isMandatory={false}
													/>
												</td>
												<td>
													<div
														style={{
															marginTop: 9,
															color: 'red',
															cursor: 'pointer',
														}}
														onClick={() => this.deleteExpenseRow(index)}
													>
														<FontAwesomeIcon icon="times" />
													</div>
												</td>
											</tr>
										))}
										<tr className="clickable" onClick={this.addExpenseRow}>
											<td colSpan="4" style={{ textAlign: 'center' }}>
												เพิ่มแถว
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="row">
								<TextArea
									title="Note"
									labelSize="s"
									keyProps="note"
									value={this.state.note}
									onInputChange={this.onTextChange}
									isMandatory={false}
								/>
							</div>
							<div style={{ textAlign: 'right', marginTop: 20 }}>
								<AuthRender roles={['Finance']}>
									<label className="checkbox">
										<input
											type="checkbox"
											value={this.state.isFinanceReviewed}
											onClick={this.toggleFinanceReviewed}
											checked={this.state.isFinanceReviewed ? 'checked' : ''}
											onChange={() => {}}
										/>
										&nbsp;ตรวจสอบโดยพนักงานบัญชีแล้ว
									</label>
								</AuthRender>
								<span> </span>
								{AdminLockRender(
									program.status,
									this.isAllowEdited(this.state.approvalStatus) ? (
										<button className="button is-success" onClick={this.save}>
											บันทึก
										</button>
									) : (
										<button className="button is-success" disabled>
											บันทึก
										</button>
									),
									<button className="button is-success" disabled>
										บันทึก
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
