import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import NumberColor from '../../../Components/NumberColor';

import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';
import ExpenseRowDetail from './ExpenseRowDetail';

export default class Expense extends PureComponent {
	summary = mode => {
		const { expenseGroup } = this.props;
		console.log('group', expenseGroup);
		let total = 0;
		expenseGroup.forEach(expense => {
			if (mode === 'total') {
				total += expense.totalIncludingNotApproved - expense.totalNotApproved;
			} else if (mode === 'paid') {
				total += expense.totalApprovedAndPaid;
			} else if (mode === 'notpaid') {
				total += expense.totalIncludingNotApproved - expense.totalNotApproved - expense.totalApprovedAndPaid;
			}
		});

		return total;
	};

	render() {
		const { expenseGroup, program } = this.props;
		return (
			<div className="section">
				<div className="columns">
					<div className="column is-6">
						<h3 className="title is-3">รายจ่าย</h3>
					</div>
					<div className="column is-6" style={{ textAlign: 'right' }}>
						{AdminLockRender(
							program.status,
							<Link className="button is-success" to={AppConfig.appPath + '/programs/incentive/' + this.props.programId + '/newexpense'}>
								เพิ่มรายการ
							</Link>,
							<button className="button is-success" disabled>
								เพิ่มรายการ
							</button>
						)}

						<span> </span>
						{/* <button className="button is-info">PDF</button> */}
					</div>
				</div>
				<div className="table-container">
					<table className="table is-hoverable is-fullwidth is-striped">
						<thead>
							<tr>
								<td width="40%">รายการ</td>
								<td width="15%" style={{ textAlign: 'right' }}>
									ยอด
								</td>
								<td width="15%" style={{ textAlign: 'right' }}>
									ยอดรวม (THB)
								</td>
								<td width="15%" style={{ textAlign: 'right' }}>
									ยอดจ่ายแล้ว (THB)
								</td>
								<td width="15%" style={{ textAlign: 'right' }}>
									ยอดค้างจ่าย (THB)
								</td>
							</tr>
						</thead>
						<tbody>
							{expenseGroup.map(expense => (
								<tr key={'expense' + expense.currency + expense.incentiveExpenseCategoryId}>
									<td>
										<Link
											to={
												AppConfig.appPath +
												'/programs/incentive/' +
												this.props.programId +
												'/expenses/' +
												expense.incentiveExpenseCategoryId
											}
										>
											{expense.incentiveExpenseCategory.name}
											<span> </span>
											{!expense.hasApproved ? <label className="tag is-warning">มีรายจ่ายรอการอนุมัติ</label> : null}
											<span> </span>
											{!expense.hasFinanceStaff ? <span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span> : null}
										</Link>
									</td>
									<td>
										<ExpenseRowDetail programId={this.props.programId} categoryId={expense.incentiveExpenseCategoryId} />
									</td>
									<td className="number-cell">
										<NumberColor number={expense.totalIncludingNotApproved - expense.totalNotApproved} isExpense={true} />
									</td>
									<td className="number-cell">
										<NumberColor number={expense.totalApprovedAndPaid} isExpense={true} />
									</td>
									<td className="number-cell">
										<NumberColor
											number={expense.totalIncludingNotApproved - expense.totalNotApproved - expense.totalApprovedAndPaid}
											isExpense={true}
										/>
									</td>
								</tr>
							))}
							<tr className="table-summary-row">
								<td colSpan={2}>Summary</td>
								<td className="number-cell">
									<NumberColor isExpense number={this.summary('total')} />
								</td>
								<td className="number-cell">
									<NumberColor isExpense number={this.summary('paid')} />
								</td>
								<td className="number-cell">
									<NumberColor isExpense number={this.summary('notpaid')} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
